<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Auto in vendita </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Auto in vendita</b-breadcrumb-item>
            <b-breadcrumb-item active> Scheda Auto</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="my-2">
        <b-row>
          <b-col md="6">
            <b-card>
              <h4 class="text-primary-light">
                {{ auto.marca }} - {{ auto.modello }}
              </h4>
              <p>{{ auto.versione }}</p>

              <div class="d-flex align-items-center justify-content-between">
                <p class="h2 m-0">€{{ auto.prezzo_rivenditore }}</p>
                <b-button variant="outline-danger" size="sm"
                  >Riserva per 24h</b-button
                >
              </div>

              <b-row>
                <b-col md="6">
                  <b-button block variant="primary-light">
                    Foto perizia
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button block variant="primary-light">
                    Stampa col tuo prezzo
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col md="6">
                  <b-button block variant="primary-light">
                    Scarica foto
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button block variant="primary-light">
                    Scarica foto perizia
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-button
                    block
                    variant="primary"
                    @click="addToCart(auto.id_auto)"
                  >
                    Aggiungi al carrello
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-card>
                  <h4 class="text-primary-light mb-2">INFO VEICOLO</h4>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <b-img
                        class="mr-1"
                        src="@/assets/icons/engine.svg"
                        height="15px"
                      />
                      <span>{{ auto.potenza }} CV</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-img
                        class="mr-1"
                        src="@/assets/icons/fuel.svg"
                        height="20px"
                      />
                      <span>{{ auto.alimentazione }}</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-img
                        class="mr-1"
                        src="@/assets/icons/calendar.svg"
                        height="20px"
                      />
                      <span>{{ auto.immatricolazione_anno }}</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-img
                        class="mr-1"
                        src="@/assets/icons/road.svg"
                        height="15px"
                      />
                      <span>{{ auto.chilometraggio }} km</span>
                    </div>

                    <div class="d-flex align-items-center">
                      <b-img
                        class="mr-1"
                        src="@/assets/icons/cambio.svg"
                        height="20px"
                      />
                      <span>{{ auto.tipo_cambio }}</span>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-card-actions title="DATI AUTO" action-collapse>
                </b-card-actions>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-card-actions title="ACCESSORI" action-collapse>
                </b-card-actions>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-card-actions title="SPECIFICHE TECNICHE" action-collapse>
                </b-card-actions>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BButton,
  BImg,
} from "bootstrap-vue";

import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BButton,
    BImg,
    BCardActions,
  },

  data: () => ({
    auto: {},

    //Helpers

    loading: false,
  }),

  created() {
    this.getAutoDetails();
  },

  methods: {
    async getAutoDetails() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_auto", this.$route.params.id);

      try {
        const response = await Requests.getAutoDetails(request_data);
        this.auto = response[0].db_auto_details.payload;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async addToCart(id_auto) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_auto", id_auto);
      this.addItem();

      try {
        await Requests.addToCart(request_data);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
